import { useI18n } from 'vue-i18n';

import { ModalsEnum, openModal } from '@/store/modals';

const errorKeys = {
  forbidden: 'errors.forbidden',
  forbiddenBlocked: 'errors.forbiddenBlocked',
  notFound: 'errors.notFound',
  'user.notFound': 'errors.user.notFound',
  'withdrawal.notCorrectCode': 'errors.withdrawal.notCorrectCode',
  'cash.amountExceedsLimit': 'errors.cash.amountExceedsLimit',
  banking: 'errors.banking',
  insufficientBalance: 'errors.insufficientBalance',
  'Withdrawal is available once a week': 'errors.withdrawal.onceWeek',
};

export default () => {
  const { t } = useI18n();

  const getMessage = (err) => {
    if (typeof err === 'string') {
      return err;
    }

    if (err?.i18nKey) {
      return t(err.i18nKey);
    }

    const messageKey = err.response?.data?.message || err.response?.data?.errorMessage;

    if (errorKeys[messageKey]) {
      return t(errorKeys[messageKey]);
    }

    return messageKey || err.message;
  };

  const handleError = (err) => {
    const message = getMessage(err);

    console.error(err);
    // eslint-disable-next-line no-alert
    window.alert(message);
  };

  const handleErrorModal = (err) => {
    const message = getMessage(err);

    console.error(err);

    openModal(ModalsEnum.DEFAULT_ERROR, {
      subtitle: message,
    });
  };

  return {
    handleError,
    handleErrorModal,
  };
};
