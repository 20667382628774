import { createApp } from 'vue';

import i18n from '@/config/i18n';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';

createApp(App)
  .use(router)
  .use(i18n)
  .mount('#app');
