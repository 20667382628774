import { checkMonthNumber } from '@/utils/date';

import GoogleTagStrategy from './GoogleTagStrategy';
// import TestMetricStrategy from './TestMetricStrategy';

export const MetricCatEnum = Object.freeze({
  COMMON: '',
  HOME: 'home',
  LOGIN: 'login',
  PAYMENT: 'payment',
  HISTORY: 'history',
  CALENDAR: 'calendar',
  STATISTICS: 'statistics',
  WITHDRAWAL: 'withdrawal',
});

class MetricTracker {
  #strategies = [];

  addStrategy(strategy) {
    if (typeof strategy.track !== 'function') {
      throw new Error('Strategy must implement track method');
    }

    this.#strategies.push(strategy);
    return this;
  }

  #trackEvent(eventName, eventType, eventData) {
    this.#strategies.forEach((strategy) => {
      strategy.track(eventName, eventType, eventData);
    });
  }

  clickButton({ cat, buttonText }) {
    this.#trackEvent('button', cat, { button_text: buttonText });
  }

  setHomeMounth({ numberMonth }) {
    this.#trackEvent('month', MetricCatEnum.HOME, {
      number_month: checkMonthNumber(numberMonth),
    });
  }

  setHomeMounthPagination({ direction, numberMonth }) {
    this.#trackEvent(direction, MetricCatEnum.HOME, {
      number_month: numberMonth,
      direction,
    });
  }

  updatePaymentUserIDField() {
    this.#trackEvent('id_field', MetricCatEnum.PAYMENT);
  }

  setHistoryPagination({ pageNumber }) {
    this.#trackEvent('page', MetricCatEnum.HISTORY, {
      page_number: pageNumber,
    });
  }

  openCalendar({ cat }) {
    this.#trackEvent('calendar', cat, { page_path: cat });
  }

  setWithdrawalAuto({ value }) {
    if (value !== 'off' && value !== 'on') {
      throw new Error(
        '[ERROR]: Error in MetricTracker setWithdrawalAuto. Value must be "off" or "on", but value was be',
        value,
      );
    }

    this.#trackEvent('autowithdrawal', MetricCatEnum.WITHDRAWAL, {
      autowithdrawal: value,
    });
  }

  updateMoneyField() {
    this.#trackEvent('money_field', MetricCatEnum.WITHDRAWAL);
  }

  updateLoginPasswordField() {
    this.#trackEvent('password_field', MetricCatEnum.LOGIN);
  }

  updateLoginNameField() {
    this.#trackEvent('login_field', MetricCatEnum.LOGIN);
  }
}

export default new MetricTracker().addStrategy(GoogleTagStrategy);
// .addStrategy(TestMetricStrategy);
