import { createRouter, createWebHistory } from 'vue-router';

import routes from '@/router/routes';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.name === from.name) {
      return savedPosition ?? null;
    }

    return savedPosition ?? { left: 0, top: 0 };
  },
});

export default router;
