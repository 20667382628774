<template>
  <div :class="$style.root">
    <div :class="$style.top">
      <div :class="$style.main">
        <Logo :class="isAuthorized || isDesktop ? $style.logo : $style.logoCenter" />
        <template v-if="isAuthorized && !isDesktop">
          <UserInfo :class="$style.user" />
          <Burger
            :is-opened="isHeaderDropdownOpened"
            @click="toggleIsHeaderDropdownOpened"
          />
        </template>
      </div>
    </div>
    <HeaderDropdown
      v-if="!isDesktop && isAuthorized"
      :class="$style.dropdown"
    >
      <NavigationBar />
    </HeaderDropdown>
    <NavigationBar
      v-else-if="isDesktop"
      :class="$style.content"
    />
  </div>
</template>

<script setup>
import { ref, provide } from 'vue';

import Burger from '@/components/Burger.vue';
import HeaderDropdown from '@/components/HeaderDropdown.vue';
import Logo from '@/components/Logo.vue';
import NavigationBar from '@/components/NavigationBar.vue';
import UserInfo from '@/components/UserInfo.vue';
import { isAuthorized } from '@/store/authorization';
import isDesktop from '@/store/isDesktop';

const isHeaderDropdownOpened = ref(false);

const setIsHeaderDropdownOpened = (value) => {
  isHeaderDropdownOpened.value = value;
};

const toggleIsHeaderDropdownOpened = () => {
  isHeaderDropdownOpened.value = !isHeaderDropdownOpened.value;
};

provide('isHeaderDropdownOpened', isHeaderDropdownOpened);
provide('setIsHeaderDropdownOpened', setIsHeaderDropdownOpened);
</script>

<style lang="scss" module>
@import "~@/styles/mixins.scss";

.root {
  display: flex;
  flex-direction: column;
  z-index: 5;
  position: relative;

  @include for-desktop {
    padding: 30px;
    background-color: $color-black;
    z-index: initial;
  }
}

.logo {
  display: block;
  margin: 0 auto 0 0;
  width: 63px;
  height: 24px;

  @include for-desktop {
    margin: 0 0 30px;
    align-self: flex-start;
    width: 84px;
    height: 32px;
  }
}

.logoCenter {
  composes: logo;
  margin: 0 auto;
}

.top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: $size-header-mobile-height;
  background-color: $color-black;
  z-index: 1;

  @include for-desktop {
    position: static;
    height: auto;
  }
}

.main {
  display: flex;
  align-items: center;
  padding: 0 $size-mobile-container-padding-x;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: $size-mobile-container-max-width;

  @include for-desktop {
    position: static;
    padding: 0;
    height: auto;
  }
}

.user {
  margin-right: 15px;
}

.dropdown {
  position: fixed;
  top: $size-header-mobile-height;
  left: 0;
  right: 0;
  bottom: 0;
}

.content {
  height: 100%;
}
</style>
