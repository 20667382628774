<template>
  <transition
    :enter-from-class="$style.enterFrom"
    :enter-active-class="$style.enterActive"
    :leave-active-class="$style.enterActive"
    :leave-to-class="$style.enterFrom"
  >
    <div
      v-show="isHeaderDropdownOpened"
      :class="$style.root"
    >
      <div :class="$style.body">
        <div :class="$style.container">
          <slot />
        </div>
        <SwipeLine :class="$style.swipeLine" />
      </div>
      <div
        :class="$style.overlay"
        @click="setIsHeaderDropdownOpened(false)"
      />
    </div>
  </transition>
</template>

<script setup>
import { inject } from 'vue';

import SwipeLine from '@/components/SwipeLine.vue';

const isHeaderDropdownOpened = inject('isHeaderDropdownOpened');
const setIsHeaderDropdownOpened = inject('setIsHeaderDropdownOpened');
</script>

<style lang="scss" module>
@import "~@/styles/variables.scss";

.body {
  border-radius: 0 0 36px 36px;
  background-color: $color-black;
  z-index: 1;
  position: relative;
  overflow: auto;
  max-height: calc(100vh - #{$size-header-mobile-height - 10px});
}

.container {
  max-width: $size-mobile-container-max-width;
  margin: auto;
  padding: 26px $size-mobile-container-padding-x 35px;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(9, 15, 30, 0.8);
  cursor: pointer;
}

.swipeLine {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 8px;
  margin: auto;
}

$transition-duration: .5s;

.enterFrom {
  .body {
    transform: translateY(-100%);
    border-radius: 0;
  }

  .overlay {
    opacity: 0;
  }
}

.enterActive {
  transition: transform $transition-duration;

  .body {
    transition: transform $transition-duration, border-radius $transition-duration;
  }

  .overlay {
    transition: opacity $transition-duration;
  }
}
</style>
