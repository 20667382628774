<template>
  <form @submit.prevent="handleSubmit">
    <AppInput
      v-model="login"
      :class="$style.input"
      :placeholder="$t('login.login')"
      autocomplete="username"
    />
    <AppInput
      v-model="password"
      :class="$style.input"
      :placeholder="$t('login.password')"
      :button-text="$t('login.forgot')"
      type="password"
      autocomplete="current-password"
      @button-click="openForgotPasswordModal"
    />
    <AppButton
      :class="$style.button"
      type="submit"
      :is-pending="isPending"
      @click="onSubmitButtonClick"
    >
      {{ $t("login.submit") }}
    </AppButton>
  </form>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { useOnFirstInputChangeCB } from '../hooks/useOnFirstInputChangeCB';
import AppButton from '@/components/AppButton.vue';
import AppInput from '@/components/AppInput.vue';
import RouteNamesEnum from '@/constant/RouteNamesEnum';
import useErrors from '@/hooks/useErrors';
import MetricTracker, { MetricCatEnum } from '@/services/MetricTrackers/MetricTracker';
import { authorize } from '@/store/authorization';
import { ModalsEnum, openModal } from '@/store/modals';

const { handleError } = useErrors();

const router = useRouter();

const login = ref('');
const password = ref('');
const isPending = ref(false);

const openForgotPasswordModal = () => {
  MetricTracker.clickButton({
    cat: MetricCatEnum.LOGIN,
    buttonText: 'restore_password',
  });
  openModal(ModalsEnum.FORGOT_PASSWORD);
};

const onSubmitButtonClick = () => {
  MetricTracker.clickButton({
    cat: MetricCatEnum.LOGIN,

    buttonText: 'login',
  });
};

const handleSubmit = async () => {
  if (isPending.value) return;

  login.value = login.value.trim();

  password.value = password.value.trim();

  if (!login.value || !password.value) {
    return;
  }

  isPending.value = true;

  try {
    await authorize(login.value, password.value);

    await router.push({ name: RouteNamesEnum.HOME });
  } catch (err) {
    handleError(err);
  } finally {
    isPending.value = false;
  }
};

useOnFirstInputChangeCB(login, () => MetricTracker.updateLoginNameField());
useOnFirstInputChangeCB(password, () => MetricTracker.updateLoginPasswordField());
</script>
<style lang="scss" module>
.input {
  margin-bottom: 10px;
}

.button {
  margin-top: 20px;
}
</style>
