import {
  LANG_LOCALSTORAGE_KEY,
  FALLBACK_LOCALE,
  localesList,
} from '@/constant/LocalesEnum';

const getNavigatorLanguage = () => {
  const language = localesList.find((lang) => navigator.language.toLowerCase().includes(lang));

  return language ?? FALLBACK_LOCALE;
};

const getLanguage = () => {
  const savedLanguage = localStorage.getItem(LANG_LOCALSTORAGE_KEY);

  const isSavedLanguageValid = Boolean(savedLanguage)
    && localesList.includes(savedLanguage);

  if (isSavedLanguageValid) {
    return savedLanguage;
  }

  const langFromNavigator = getNavigatorLanguage();
  localStorage.setItem(LANG_LOCALSTORAGE_KEY, langFromNavigator);

  return langFromNavigator;
};

export default getLanguage;
