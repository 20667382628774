<template>
  <div :class="isFullButtons ? $style.rootFull : $style.root">
    <a
      v-for="item in socials"
      :key="item.key"
      :href="item.link"
      :class="item.className"
      target="_blank"
      @click="socialClickHandler(item.text)"
    >
      <Icon
        :class="$style.icon"
        :name="item.icon"
      />
      <span v-if="isFullButtons">{{ item.text }}</span>
    </a>
  </div>
</template>

<script>
import { bool } from 'vue-types';

import Icon from '@/components/Icon.vue';
import MetricTracker, { MetricCatEnum } from '@/services/MetricTrackers/MetricTracker';

export default {
  components: {
    Icon,
  },

  props: {
    isFullButtons: bool().def(false),
  },

  computed: {
    socials() {
      return [
        {
          key: 'telegram',
          className: this.$style.telegram,
          link: process.env.TELEGRAM_URL,
          text: 'Telegram',
          icon: 'telegram',
        },
        {
          key: 'whatsApp',
          className: this.$style.whatsApp,
          link: process.env.WHATSAPP_URL,
          text: 'WhatsApp',
          icon: 'whatsapp',
        },
        {
          key: 'email',
          className: this.$style.email,
          link: `mailto:${process.env.EMAIL}`,
          text: 'Email',
          icon: 'email',
        },
      ];
    },
  },

  methods: {
    socialClickHandler(buttonText = '') {
      MetricTracker.clickButton({
        cat: MetricCatEnum.COMMON,
        buttonText: `support_${buttonText?.toLowerCase?.()}`,
      });
    },
  },
};
</script>

<style lang="scss" module>
@import "~@/styles/variables.scss";

.root {
  display: flex;
}

.item {
  width: 35px;
  height: 35px;
  border-radius: 8px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: $color-white;

  &:last-child {
    margin-right: 0;
  }
}

.telegram {
  composes: item;
  background: linear-gradient(240.2deg, #0fb1d6 0%, #0088cc 61.34%);
}

.whatsApp {
  composes: item;
  background: linear-gradient(89.67deg, #3dd078 0.57%, #089e4e 99.35%);
}

.email {
  composes: item;
  background: linear-gradient(98.44deg, #5e9fff 0%, #004dbd 95.33%);
}

.icon {
  font-size: 18px;
}

.rootFull {
  composes: root;

  .item {
    width: auto;
    flex-grow: 1;
    flex-basis: 0;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  .icon {
    margin-right: 5px;
  }
}
</style>
