import { ref, computed, watch } from 'vue';

import { getMainInfo } from '@/api/main';
import { isAuthorized } from '@/store/authorization';
import { pick } from '@/utils/helpers';

const transformProfit = (profit) => pick(profit, ['deposit', 'total', 'withdrawal']);

const transformInfo = ({
  agent: {
    id: userId,
    name: userName,
  },
  remain_limit: remainLimit,
  ...info
}) => ({
  userId,
  userName,
  remainLimit,
  profit: transformProfit(info.profit ?? {}),
  ...pick(info, ['balance', 'currency', 'limit', 'isConfirmAgentAgreement', 'paymentLimits', 'haveSubagents']),
});

export const info = ref(null);

export const isInfoLoaded = computed(() => info.value !== null);

export const getInfo = async () => {
  const response = await getMainInfo();

  info.value = transformInfo(response.data);
};

watch(isAuthorized, (value) => {
  if (value) {
    getInfo();
    return;
  }

  info.value = null;
});
