import { ref } from 'vue';

import variables from '@/styles/variables.module.scss';

const mediaQueryList = window.matchMedia(`(min-width: ${variables.desktopMinWidth})`);

const isDesktop = ref(mediaQueryList.matches);

try {
  // Chrome & Firefox
  mediaQueryList.addEventListener('change', (e) => {
    isDesktop.value = e.matches;
  });
} catch (e1) {
  try {
    // Safari
    mediaQueryList.addListener((e) => {
      isDesktop.value = e.matches;
    });
  } catch (e2) {
    console.error(e2);
  }
}

export default isDesktop;
