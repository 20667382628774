<template>
  <div
    :class="$style.root"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <slot />
    <transition
      :enter-active-class="$style.enterActive"
      :leave-active-class="$style.enterActive"
      :enter-from-class="$style.enterFrom"
      :leave-to-class="$style.enterFrom"
    >
      <div
        v-if="showDropdown"
        :class="$style.dropdown"
      >
        <div
          :class="$style.triangle"
          :style="{ backgroundImage: `url(${dropdownTriangle})` }"
        />
        <slot name="content" />
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref } from 'vue';

import dropdownTriangle from '@/assets/images/dropdownTriangls.svg';

const showDropdown = ref(false);

const handleMouseEnter = () => {
  showDropdown.value = true;
};

const handleMouseLeave = () => {
  showDropdown.value = false;
};
</script>

<style lang="scss" module>
.root {
  position: relative;
}

.dropdown {
  position: absolute;
  top: calc(100% + 9px);
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  background-color: #fff;
  padding: 5px 0;
  border-radius: 8px;
  filter: drop-shadow(0px 6px 22px rgba(0, 0, 0, 0.3));

  &:before {
    content: '';
    position: absolute;
    bottom: 100%;
    right: 0;
    left: 0;
    height: 9px;
  }
}

.triangle {
  width: 17px;
  height: 8px;
  position: absolute;
  top: -7px;
  right: 0;
  left: 0;
  margin: auto;
}

.enterActive {
  transition: opacity .3s ease;
}

.enterFrom {
  opacity: 0;
}
</style>
