export default class GoogleTagStrategy {
  static track(eventName, eventType, eventData = {}) {
    window.dataLayer ??= [];

    const eventTypeString = eventType ? `_${eventType}` : '';

    window.dataLayer.push({
      event: `send_pwa${eventTypeString}_events`,
      event_cat: `pwa${eventTypeString}`,
      event_name: eventName,
      ...eventData,
    });
  }
}
