<template>
  <RouterLink
    :to="to"
    :class="$style.root"
  >
    <Icon
      :class="$style.arrow"
      name="angle"
    />
    <slot />
  </RouterLink>
</template>

<script>
import Icon from '@/components/Icon.vue';
import { isRouterLinkTo } from '@/types/routerLink';

export default {
  components: {
    Icon,
  },

  props: {
    to: isRouterLinkTo.isRequired,
  },
};
</script>

<style lang="scss" module>
@import "~@/styles/variables.scss";

.root {
  color: $color-blue;
  display: flex;
  align-items: center;
  font-weight: 500;
}

.arrow {
  transform: rotate(180deg);
  font-size: 12px;
  margin-right: 4px;
}
</style>
