<template>
  <nav :class="$style.root">
    <div :class="$style.links">
      <div
        v-for="item in items"
        :key="item.key"
        :class="$style.item"
      >
        <RouterLink
          :to="item.to"
          :class="$style.link"
          :active-class="$style.active"
          @click="handleLinkClick(item.to.name)"
        >
          <Icon
            :class="$style.icon"
            :name="item.icon"
          />
          <span>{{ item.text }}</span>
        </RouterLink>
      </div>
    </div>
    <div :class="$style.logoutWrapper">
      <button
        :class="$style.logout"
        type="button"
        @click="logoutHandler"
      >
        <Icon
          :class="$style.icon"
          name="logout"
        />
        <span>{{ $t("navigation.logout") }}</span>
      </button>
      <LangSelector />
    </div>
  </nav>
</template>

<script>
import Icon from '@/components/Icon.vue';
import LangSelector from '@/components/LangSelector.vue';
import RouteNamesEnum from '@/constant/RouteNamesEnum';
import MetricTracker, { MetricCatEnum } from '@/services/MetricTrackers/MetricTracker';
import { logout } from '@/store/authorization';

export default {
  components: {
    Icon,
    LangSelector,
  },

  inject: ['setIsHeaderDropdownOpened'],

  computed: {
    items() {
      return [
        {
          key: 'main',
          to: { name: RouteNamesEnum.HOME },
          text: this.$t('navigation.home'),
          icon: 'home',
        },
        {
          key: 'withdrawals',
          to: { name: RouteNamesEnum.WITHDRAWALS },
          text: this.$t('navigation.payments'),
          icon: 'repeat',
        },
        {
          key: 'history',
          to: { name: RouteNamesEnum.HISTORY },
          text: this.$t('navigation.history'),
          icon: 'clock',
        },
        {
          key: 'statistics',
          to: { name: RouteNamesEnum.STATISTICS },
          text: this.$t('navigation.statistics'),
          icon: 'pie-chart',
        },
        {
          key: 'agentWithdrawals',
          to: { name: RouteNamesEnum.AGENT_WITHDRAWALS },
          text: this.$t('navigation.agentWithdrawals'),
          icon: 'dollar',
        },
      ];
    },
  },

  methods: {
    handleLinkClick(to) {
      MetricTracker.clickButton({
        cat: MetricCatEnum.COMMON,
        buttonText: this.mapNavigationNameToMetric(to),
      });
      this.setIsHeaderDropdownOpened(false);
    },

    mapNavigationNameToMetric(nav) {
      switch (nav) {
        case RouteNamesEnum.HOME:
          return MetricCatEnum.HOME;
        case RouteNamesEnum.WITHDRAWALS:
          return MetricCatEnum.PAYMENT;
        case RouteNamesEnum.HISTORY:
          return MetricCatEnum.HISTORY;
        case RouteNamesEnum.STATISTICS:
          return MetricCatEnum.STATISTICS;
        case RouteNamesEnum.AGENT_WITHDRAWALS:
          return MetricCatEnum.WITHDRAWAL;
        default:
          return '';
      }
    },
    logoutHandler() {
      MetricTracker.clickButton({
        cat: MetricCatEnum.COMMON,
        buttonText: 'exit',
      });
      logout();
    },
  },
};
</script>

<style lang="scss" module>
@import "~@/styles/variables.scss";

.root {
  display: flex;
  flex-direction: column;
}

.item {
  margin-bottom: 15px;
}

.link {
  height: 46px;
  display: inline-flex;
  align-items: center;
  align-self: flex-start;
  color: $color-white;
  font-size: 16px;
  line-height: 1.2;
  word-break: break-word;
  cursor: pointer;
}

.icon {
  font-size: 24px;
  color: $color-white;
  margin-right: 15px;
  flex-shrink: 0;
}

.links {
  margin-bottom: auto;
}

.logoutWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 25px;
  padding-bottom: 10px;
}

.logout {
  composes: link;
  border: 0;
  background: transparent;
  padding: 0;
  text-align: left;
  margin-right: 5px;
}

.active {
  color: $color-blue;
  font-weight: bold;

  .icon {
    color: $color-blue;
  }
}
</style>
