import axios from 'axios';

const http = axios.create({
  baseURL: process.env.SERVER_HOST,
  headers: { 'Content-Type': 'application/json' },
  transformRequest: [(data) => JSON.stringify({ data })],
});

// По новому регламенту для новых ручек поменялся baseURL
export const httpV1 = axios.create({
  baseURL: process.env.SERVER_HOST_V1,
  headers: { 'Content-Type': 'application/json' },
});

/**
 * @param {string} name
 * @param {string} value
 */
export const setDefaultHeader = (name, value) => {
  http.defaults.headers.common[name] = value;
  httpV1.defaults.headers.common[name] = value;
};

/**
 * @param {string} name
 */
export const removeDefaultHeader = (name) => {
  delete http.defaults.headers.common[name];
  delete httpV1.defaults.headers.common[name];
};

/**
 * @param {function} onFulfilled
 * @param {function} onRejected
 */
export const setResponseInterceptor = (onFulfilled, onRejected) => {
  http.interceptors.response.use(onFulfilled, onRejected);
  httpV1.interceptors.response.use(onFulfilled, onRejected);
};

export default http;
