const resource = {
  "forgotPassword": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot your password?"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To restore your account, contact your personal manager or support"])}
  },
  "confirmReplenishment": {
    "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Do you really want to fund the account with this amount for user #", _interpolate(_named("userId")), "?"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
  },
  "replenishmentSuccess": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account successfully funded"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])}
  },
  "paymentCompleted": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment completed successfully"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
    "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal rejected"])}
  },
  "error": {
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
  }
}
export default resource