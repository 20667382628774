import {
  object,
  oneOfType,
  shape,
  string,
} from 'vue-types';

export const isRouterLinkTo = oneOfType([
  string(),
  shape({
    name: string(),
    params: object(),
  }),
]);
