import { createI18n } from 'vue-i18n';

import {
  FALLBACK_LOCALE,
  LANG_LOCALSTORAGE_KEY,
  localesList,
} from '@/constant/LocalesEnum';
import en from '@/locales/en';
import getLanguage from '@/utils/getLanguage';

const messages = {
  en,
};

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: FALLBACK_LOCALE,
  fallbackLocale: FALLBACK_LOCALE,
  messages,
});

export const localesImports = localesList.reduce((acc, locale) => {
  acc[locale] = () => import(`@/locales/${locale}/`);
  return acc;
}, {});

export const setLocale = async (locale) => {
  try {
    const loadedLocale = await localesImports[locale]();

    i18n.global.setLocaleMessage(locale, loadedLocale.default);
    i18n.global.locale.value = locale;

    localStorage.setItem(LANG_LOCALSTORAGE_KEY, locale);
  } catch (err) {
    console.error(
      `Could not load locale: ${locale}`,
      err,
    );
  }
};

export const setCurrentLanguage = async () => {
  const currentLanguage = getLanguage();

  if (i18n.global.locale.value !== currentLanguage) {
    await setLocale(currentLanguage);
  }
};

export default i18n;
