const resource = {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent withdrawals"])},
  "historyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal history"])},
  "withdrawalTextInfo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["*Withdrawals are available every Wednesday. After moderation, the wins will be credited into your account ", _interpolate(_named("email"))])},
  "autoWithdrawal": {
    "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-withdrawal activated"])},
    "off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-withdrawal deactivated"])}
  },
  "withdrawals": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal request"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player account"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send request"])}
  },
  "info": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal information"])},
    "count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of withdrawals"])},
    "totalSum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total withdrawn"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal history"])}
  },
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "errors": {
    "min": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Minimum withdrawal amount — ", _interpolate(_named("min"))])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The amount requested exceeds your current income"])}
  },
  "createWithdrawal": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal request submitted"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
  }
}
export default resource