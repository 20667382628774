import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import { RTL_LOCALES } from '@/constant/LocalesEnum';

export const useRtlLocales = () => {
  const { locale } = useI18n();

  const isRtlLocale = computed(() => RTL_LOCALES.includes(locale.value));

  const rtlClasses = computed(() => ({
    rtl: isRtlLocale.value,
  }));

  return {
    isRtlLocale,
    rtlClasses,
  };
};
