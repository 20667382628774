import { ref } from 'vue';

import authorizeRequest from '@/api/authorize';
import { removeDefaultHeader, setDefaultHeader, setResponseInterceptor } from '@/utils/http';

const AUTHORIZATION_TOKEN_LOCALSTORAGE_KEY = 'authorization-key';
const AUTHORIZATION_HEADER_NAME = 'Authorization';

export const isAuthorized = ref(false);

export const checkAuthorization = () => {
  const authorizationToken = window.localStorage.getItem(AUTHORIZATION_TOKEN_LOCALSTORAGE_KEY);
  if (!authorizationToken) {
    return false;
  }

  isAuthorized.value = true;
  setDefaultHeader(AUTHORIZATION_HEADER_NAME, authorizationToken);
  return true;
};

export const logout = () => {
  window.localStorage.removeItem(AUTHORIZATION_TOKEN_LOCALSTORAGE_KEY);
  removeDefaultHeader(AUTHORIZATION_HEADER_NAME);
  isAuthorized.value = false;
};

export const authorize = async (login, password) => {
  try {
    const { data } = await authorizeRequest(login, password);

    const token = `Bearer ${data}`;

    window.localStorage.setItem(AUTHORIZATION_TOKEN_LOCALSTORAGE_KEY, token);
    setDefaultHeader(AUTHORIZATION_HEADER_NAME, token);

    isAuthorized.value = true;
  } catch (err) {
    logout();

    console.error(err);
    throw err;
  }
};

setResponseInterceptor(null, (err) => {
  if (isAuthorized.value && err.response?.status === 403) {
    logout();
  }

  throw err;
});
