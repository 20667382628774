<template>
  <div :class="$style.root">
    1wincash | © {{ year }} 1win
  </div>
</template>

<script>
export default {
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style lang="scss" module>
.root {
  font-size: 11px;
}
</style>
