const resource = {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])},
  "depositsCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposits count"])},
  "withdrawalsCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawals count"])},
  "profitDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profit from deposits"])},
  "profitWithdrawal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profit from withdrawals"])},
  "profitTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total profit"])},
  "countUniqueUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unique players count"])},
  "sumDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount of deposits"])},
  "sumWithdrawal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount of withdrawals"])},
  "sumAgentOwnDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount of agent's deposits"])},
  "tabs": {
    "common": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General"])},
    "subagentship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subagents"])}
  },
  "subagentship": {
    "profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenue"])}
  }
}
export default resource