export class FingerprintService {
  constructor(apiToken) {
    this.apiToken = apiToken;
  }

  static isPro(fingerprint) {
    return 'requestId' in fingerprint.value;
  }

  static getFingerPrintData(fingerprint) {
    const { visitorId, ipLocation } = fingerprint.value;

    if (FingerprintService.isPro(fingerprint)) {
      return {
        clientId: visitorId,
        country: ipLocation?.country.code,
      };
    }

    return { visitorId };
  }

  async getFingerprint() {
    try {
      const getFingerprint = (lib) => lib.load({ token: this.apiToken })
        .then((fp) => fp.get({ extendedResult: true }));

      const fingerprints = await Promise.allSettled([
        import('@fingerprintjs/fingerprintjs').then(getFingerprint),
        import('@fingerprintjs/fingerprintjs-pro').then(getFingerprint),
      ]);

      const result = {
        clientId: '',
        visitorId: '',
        country: '',
      };

      fingerprints.forEach((fingerprint) => {
        if (fingerprint.status === 'fulfilled') {
          Object.assign(result, FingerprintService.getFingerPrintData(fingerprint));
        }
      });

      return result;
    } catch (e) {
      return null;
    }
  }
}

export const fingerprintService = new FingerprintService(process.env.FINGERPRINT_API_KEY);
