const resource = {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
  "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player Information"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation date"])},
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
  "payoutAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payout amount"])},
  "readyToPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready to pay"])},
  "enterCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a code"])},
  "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check"])},
  "approve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm and pay"])},
  "confirmReject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject withdrawal?"])},
  "reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reject withdrawal"])}
}
export default resource