const RouteNamesEnum = {
  HOME: 'Home',
  WITHDRAWALS: 'Withdrawals',
  HISTORY: 'History',
  REFILL: 'Refill',
  WITHDRAWAL_DETAILS: 'WithdrawalDetails',
  STATISTICS: 'Statistics',
  AGENT_WITHDRAWALS: 'AgentWithdrawals',
  AGENT_WITHDRAWALS_HISTORY: 'AgentWithdrawalsHistory',
  RULES: 'Rules',
};

export default RouteNamesEnum;
