import { ref } from 'vue';

import { setCurrentLanguage } from '@/config/i18n';
import { checkAuthorization } from '@/store/authorization';

export const LoadingStatesEnum = {
  LOADING: Symbol('loading'),
  SUCCESS: Symbol('loading'),
  ERROR: Symbol('loading'),
};

export const appLoadingState = ref(LoadingStatesEnum.LOADING);

export const loadApplication = async () => {
  try {
    await setCurrentLanguage();
    checkAuthorization();
    appLoadingState.value = LoadingStatesEnum.SUCCESS;
  } catch (err) {
    appLoadingState.value = LoadingStatesEnum.ERROR;
  }
};
