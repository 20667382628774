<template>
  <div :class="rootClassName">
    <button
      :class="$style.button"
      :type="type"
    >
      <PreloaderCircle
        v-if="isPending"
        :class="$style.preloader"
      />
      <slot v-else />
    </button>
  </div>
</template>

<script>
import { bool, string, oneOf } from 'vue-types';

import PreloaderCircle from '@/components/PreloaderCircle.vue';

export const ThemesEnum = {
  DEFAULT: 'default',
  CONFIRM: 'confirm',
  CANCEL: 'cancel',
  ERROR: 'error',
};

const classNameByTheme = {
  [ThemesEnum.DEFAULT]: 'root',
  [ThemesEnum.CONFIRM]: 'rootConfirm',
  [ThemesEnum.CANCEL]: 'rootCancel',
  [ThemesEnum.ERROR]: 'rootError',
};

export default {
  components: {
    PreloaderCircle,
  },

  props: {
    type: string().def('button'),
    theme: oneOf(Object.values(ThemesEnum)).def(ThemesEnum.DEFAULT),
    isPending: bool().def(false),
  },

  computed: {
    rootClassName() {
      return this.$style[classNameByTheme[this.theme]];
    },
  },
};
</script>

<style lang="scss" module>
@import "~@/styles/variables.scss";

.root {
  position: relative;
  border-radius: 10px;
  height: 40px;
  color: $color-white;
  background-color: $color-blue;
  cursor: pointer;
}

.button {
  border: none;
  background: transparent;
  width: 100%;
  height: 100%;
  color: inherit;
  cursor: inherit;
}

.preloader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.rootConfirm {
  composes: root;
  background: linear-gradient(88.93deg, #31BC69 1.83%, #089E4E 98.17%);
}

.rootCancel {
  composes: root;
  background-color: rgba($color-white, .1);
}

.rootError {
  composes: root;
  background-color: $color-red;
}
</style>
