import { ref, computed, defineAsyncComponent } from 'vue';

const currentModal = ref(null);

// The values must match the filenames in @/components/modals/
export const ModalsEnum = {
  FORGOT_PASSWORD: 'ForgotPasswordModal',
  REFILL: 'RefillModal',
  WITHDRAWAL_DONE: 'WithdrawalDoneModal',
  CREATE_WITHDRAWAL_MODAL: 'CreateWithdrawalModal',
  CALENDAR: 'CalendarModal',
  DEFAULT_ERROR: 'DefaultErrorModal',
  RULES: 'RulesModal',
};

const modalsComponentsMap = Object.values(ModalsEnum).reduce((acc, current) => {
  acc[current] = defineAsyncComponent(
    () => import((`@/components/modals/${current}.vue`)),
  );

  return acc;
}, {});

export const modalComponent = computed(() => modalsComponentsMap[currentModal.value]);

export const isModalOpened = computed(() => currentModal.value !== null);

export const modalMeta = ref(null);

export const closable = ref(true);

export const openModal = (
  modal,
  { closable: isClosable = true, ...meta } = {},
) => {
  currentModal.value = modal;
  modalMeta.value = meta;
  closable.value = isClosable;
};

export const closeModal = () => {
  currentModal.value = null;
};
