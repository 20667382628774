<template>
  <svg
    v-if="iconMeta"
    :class="$style.root"
    :viewBox="iconMeta.viewBox"
  >
    <use :href="iconMeta.url" />
  </svg>
  <span
    v-else
    :class="$style.root"
  />
</template>

<script setup>
import { shallowRef, watchEffect } from 'vue';
import { string } from 'vue-types';

const props = defineProps({
  name: string().isRequired,
});

const iconMeta = shallowRef();

watchEffect(async () => {
  try {
    const icon = await import(
      /* webpackChunkName: "iconsChunk" */
      /* webpackMode: "lazy-once" */
      `@/assets/icons/${props.name}.svg`
    );

    iconMeta.value = icon.default;
  } catch {
    iconMeta.value = null;
    console.error(`Unable to load "${props.name}" icon`);
  }
});
</script>

<style lang="scss" module>
.root {
  max-width: 100%;
  width: 1em;
  height: 1em;
}
</style>
