<template>
  <div :class="$style.root">
    <div
      v-if="!isDesktop"
      :class="$style.text"
    >
      {{ locale }}
    </div>
    <div :class="$style.arrow">
      <Icon
        :class="$style.arrowIcon"
        name="angle"
      />
    </div>
    <CountryFlag :country="locale" />
    <select
      :class="$style.select"
      @change="handleLocaleChange($event.target.value)"
    >
      <option
        v-for="{ value, text } in locales"
        :key="value"
        :value="value"
        :selected="value === locale"
      >
        {{ text }}
      </option>
    </select>
    <Dropdown :class="$style.dropdown">
      <template #content>
        <div
          v-for="localeItem in localesList"
          :key="localeItem"
          :class="$style.localeDropdownItem"
          @click="handleLocaleChange(localeItem)"
        >
          <CountryFlag
            :class="$style.dropdownItemFlag"
            :country="localeItem"
          />
          <div>
            {{ localeItem }}
          </div>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import CountryFlag from '@/components/CountryFlag.vue';
import Dropdown from '@/components/Dropdown.vue';
import Icon from '@/components/Icon.vue';
import { setLocale } from '@/config/i18n';
import { localesList, languageNames } from '@/constant/LocalesEnum';
import isDesktop from '@/store/isDesktop';

const { locale } = useI18n();

const locales = computed(() => localesList.map((item) => ({
  value: item,
  text: languageNames[item],
})));

const handleLocaleChange = (value) => {
  setLocale(value);
};
</script>

<style lang="scss" module>
@import "~@/styles/mixins.scss";
@import "~@/styles/variables.scss";

.root {
  background-color: #141B2E;
  border-radius: 6px;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  position: relative;
}

.text {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  margin-right: 3px;
  color: $color-white;
  text-transform: uppercase;
}

.arrow {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  position: relative;
}

.arrowIcon {
  font-size: 9px;
  transform: rotate(90deg);
  color: $color-white;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.select {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;

  @include for-desktop {
    display: none;
  }
}

.dropdown {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  @include for-desktop {
    display: block;
  }
}

.localeDropdownItem {
  display: flex;
  align-items: center;
  padding: 5px 23px 5px 15px;
  text-transform: uppercase;
  line-height: 18px;
  cursor: pointer;
  transition: background-color .2s;

  &:hover {
    background-color: rgba(151, 174, 225, 0.2);
  }
}

.dropdownItemFlag {
  margin-right: 8px;
}
</style>
