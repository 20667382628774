/**
 * @template {Record<string, any>} T
 * @template {keyof T} K
 *
 * @param {T} object
 * @param {K[]} keys
 * @returns {Pick<T, K>}
 */
export const pick = (object, keys) => Object.fromEntries(
  Object
    .entries(object)
    .filter(([key]) => keys.includes(key)),
);

/**
 * @template T
 *
 * @param {() => T[keyof T]} getValue
 * @param {string} key
 * @param {T} cache
 * @return {T[keyof T]}
 */
export const getFromCache = (getValue, key, cache) => {
  if (!cache[key]) {
    // eslint-disable-next-line no-param-reassign
    cache[key] = getValue();
  }

  return cache[key];
};
