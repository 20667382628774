import RouteNamesEnum from '@/constant/RouteNamesEnum';
import RoutesMetaEnum from '@/constant/RoutesMetaEnum';

/**
 * @param {string} routeName
 * @param {string} langKey
 * @return {{}}
 */
const getBreadcrumbsMeta = (routeName, langKey) => ({
  [RoutesMetaEnum.BREADCRUMBS]: {
    to: {
      name: routeName,
    },
    langKey,
  },
});

const routes = [
  {
    path: '',
    redirect: { name: RouteNamesEnum.HOME },
  },
  {
    path: '/home',
    name: RouteNamesEnum.HOME,
    component: () => import('@/pages/Home.vue'),
    meta: {
      [RoutesMetaEnum.TITLE_KEY]: 'navigation.home',
    },
  },
  {
    path: '/withdrawals',
    name: RouteNamesEnum.WITHDRAWALS,
    component: () => import('@/pages/Withdrawals.vue'),
    meta: {
      ...getBreadcrumbsMeta(RouteNamesEnum.HOME, 'navigation.home'),
      [RoutesMetaEnum.TITLE_KEY]: 'navigation.payments',
    },
  },
  {
    path: '/withdrawal-details/:id',
    name: RouteNamesEnum.WITHDRAWAL_DETAILS,
    component: () => import('@/pages/WithdrawalDetails.vue'),
    meta: {
      ...getBreadcrumbsMeta(RouteNamesEnum.WITHDRAWALS, 'navigation.payments'),
      [RoutesMetaEnum.TITLE_KEY]: 'details.title',
    },
  },
  {
    path: '/history',
    name: RouteNamesEnum.HISTORY,
    component: () => import('@/pages/History.vue'),
    meta: {
      [RoutesMetaEnum.TITLE_KEY]: 'navigation.history',
    },
  },
  {
    path: '/home/refill',
    name: RouteNamesEnum.REFILL,
    component: () => import('@/pages/Refill.vue'),
    meta: {
      ...getBreadcrumbsMeta(RouteNamesEnum.HOME, 'navigation.home'),
      [RoutesMetaEnum.TITLE_KEY]: 'refill.title',
    },
  },
  {
    path: '/statistics',
    name: RouteNamesEnum.STATISTICS,
    component: () => import('@/pages/Statistics.vue'),
    meta: {
      [RoutesMetaEnum.TITLE_KEY]: 'navigation.statistics',
    },
  },
  // Фича флаг под автовыводы
  ...(process.env.IS_AUTOWITHDRAWALS_ENABLED === 'true' ? [
    {
      path: '/agent-withdrawals',
      name: RouteNamesEnum.AGENT_WITHDRAWALS,
      component: () => import('@/pages/AgentWithdrawals.vue'),
      meta: {
        [RoutesMetaEnum.TITLE_KEY]: 'navigation.agentWithdrawals',
      },
    },
    {
      path: '/agent-withdrawals/history',
      name: RouteNamesEnum.AGENT_WITHDRAWALS_HISTORY,
      component: () => import('@/pages/AgentWithdrawalsHistory.vue'),
      meta: {
        ...getBreadcrumbsMeta(RouteNamesEnum.AGENT_WITHDRAWALS, 'agentWithdrawals.back'),
        [RoutesMetaEnum.TITLE_KEY]: 'navigation.agentWithdrawals',
      },
    },
  ] : [
    {
      path: '/agent-withdrawals',
      name: RouteNamesEnum.AGENT_WITHDRAWALS,
      component: () => import('@/pages/AgentWithdrawalsHistory.vue'),
      meta: {
        [RoutesMetaEnum.TITLE_KEY]: 'navigation.agentWithdrawals',
      },
    },
  ]),
  {
    path: '/rules',
    name: RouteNamesEnum.RULES,
    component: () => import('@/pages/Rules.vue'),
    meta: {
      ...getBreadcrumbsMeta(RouteNamesEnum.HOME, 'navigation.home'),
      [RoutesMetaEnum.TITLE_KEY]: 'rules.title',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      name: RouteNamesEnum.HOME,
    },
  },
];

export default routes;
