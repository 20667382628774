<template>
  <div :class="$style.root" />
</template>

<style lang="scss" module>
@import "~@/styles/variables.scss";

.root {
  background-color: rgba($color-white, .1);
  width: 49px;
  height: 5px;
  border-radius: 100px;
}
</style>
