<template>
  <div :class="$style.root">
    <Navigation
      v-if="isAuthorized"
      :class="$style.nav"
    />
    <div :class="$style.info">
      <div :class="$style.infoHeader">
        {{ $t("headerContent.support") }}
      </div>
      <div :class="$style.infoText">
        {{ $t("headerContent.writeUs") }}
      </div>
      <Socials
        :class="$style.socials"
        :is-full-buttons="!isDesktop"
      />
      <RouterLink
        v-if="isAuthorized"
        :class="$style.rulesLink"
        :to="{ name: RouteNamesEnum.RULES }"
        @click="clickAgreementsHandler"
      >
        {{ $t("rules.agentAgreement") }}
      </RouterLink>
      <Copyright :class="$style.copyright" />
    </div>
  </div>
</template>

<script setup>
import { inject } from 'vue';

import Copyright from '@/components/Copyright.vue';
import Navigation from '@/components/Navigation.vue';
import Socials from '@/components/Socials.vue';
import RouteNamesEnum from '@/constant/RouteNamesEnum';
import MetricTracker, { MetricCatEnum } from '@/services/MetricTrackers/MetricTracker';
import { isAuthorized } from '@/store/authorization';
import isDesktop from '@/store/isDesktop';

const setIsHeaderDropdownOpened = inject('setIsHeaderDropdownOpened');

const clickAgreementsHandler = () => {
  MetricTracker.clickButton({ cat: MetricCatEnum.COMMON, buttonText: 'agreement' });
  setIsHeaderDropdownOpened(false);
};
</script>

<style lang="scss" module>
@import "~@/styles/variables.scss";

.root {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.nav {
  flex-grow: 1;
  border-bottom: 1px solid rgba(151, 174, 225, 0.2);
}

.info {
  margin-top: 20px;
}

.infoHeader {
  color: $color-white;
  font-size: 14px;
  line-height: 13px;
  margin-bottom: 6px;
}

.infoText {
  color: rgba($color-gray, 0.7);
  font-size: 11px;
  line-height: 15px;
  margin-bottom: 12px;
}

.socials {
  margin-bottom: 30px;
}

.rulesLink {
  color: $color-blue;
}

.copyright {
  margin-top: 10px;
  margin-bottom: 3px;
}
</style>
