import { fingerprintService } from '@/services/FingerprintService';
import http from '@/utils/http';
import { removeEmptyFields } from '@/utils/removeEmptyFields';

/**
 * @param {string} login
 * @param {string} password
 * @return {Promise<{ data: string }>}
 */
const authorize = async (login, password) => {
  const fingerprint = await fingerprintService.getFingerprint();

  const fields = removeEmptyFields({
    fingerprintId: fingerprint?.visitorId,
    clientId: fingerprint?.clientId,
    userAgent: window.navigator?.userAgent,
  });

  return http.post('/login', {
    login,
    password,
    ...fields,
  });
};

export default authorize;
