<template>
  <div :class="$style.root" />
</template>

<style lang="scss" module>
@import "~@/styles/variables.scss";

.root {
  position: relative;
  width: 15px;
  height: 15px;
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 2px;
    background-color: $color-white;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}
</style>
