export function checkMonthNumber(numberMonth) {
  if (numberMonth > 12 || numberMonth <= 0) {
    throw new Error(
      '[ERROR]: Error in setHomeMounth fn, numberMonth must be 1-12, current value:',
      numberMonth,
    );
  }

  return numberMonth;
}
