<template>
  <div :class="isOpened ? $style.rootOpened : $style.root">
    <div
      v-for="n in 3"
      :key="n"
      :class="$style[`line${n}`]"
    />
  </div>
</template>

<script>
import { bool } from 'vue-types';

export default {
  props: {
    isOpened: bool().def(false),
  },
};
</script>

<style lang="scss" module>
@import "~@/styles/variables.scss";

.root {
  position: relative;
  width: 36px;
  height: 36px;
  border: 1px solid rgba(151, 174, 225, 0.11);
  border-radius: 10px;
  transition: background-color .2s, border-color .2s;
}

.line {
  width: 18px;
  height: 2px;
  background-color: $color-white;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: transform .2s;
}

.line1 {
  composes: line;
  transform: translateY(-6px);
}

.line2 {
  composes: line;
}

.line3 {
  composes: line;
  transform: translateY(6px);
}

.rootOpened {
  composes: root;
  background-color: rgba(151, 174, 225, 0.11);
  border-color: transparent;

  .line1 {
    transform: rotate(45deg);
  }

  .line2 {
    opacity: 0;
  }

  .line3 {
    transform: rotate(-45deg);
  }
}
</style>
